<template>
  <div>
    <v-card
      v-for="({collectionId, relations}) in relationsGroupedByCollection"
      :key="collectionId">
      <v-card-title>{{collectionsConfig.getCollectionLabel(collectionId)}}</v-card-title>
      <v-card-text>
        <v-data-table
          v-sortable-table="'.drag-handle'"
          :items="relations"
          item-key="relationId"
          :hide-default-headers="true"
          :hide-default-actions="true"
          @drag-sorted="sortedInGroup($event, relations, relationsGroupedByCollection)"
        >
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="schema in items" :key="schema.relationId">
                <td class="drag-handle">
                  <v-icon>swap_vert</v-icon>
                  {{schema.relationId}}
                </td>
                <td>
                  {{collectionsConfig.getCollectionLabel(schema.sourceCollectionId)}}
                </td>
                <td>
                  &rarr;
                </td>
                <td>
                  {{collectionsConfig.getCollectionLabel(schema.targetCollectionId)}}
                </td>
                <td>
                  <v-text-field :label="translate('Display name', 'displayname')" :placeholder="schema.label" v-model="schema.label" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import SortableTable from '@/directives/sortable-table'
import groupBy from 'lodash/groupBy'
export default {
  props: ['relationClientSettings', 'collectionSchemas', 'relationSchemas'],
  directives: {
    SortableTable
  },
  computed: {
    ...mapGetters(['collectionsConfig', 'relationsConfig', 'translate']),
    relationsGroupedByCollection () {
      /**
        * General idea is
        * - get sorted collections
        * - and group relations by source collection
        * - and within that group allow sorting of relations
        *
        * Order (order property) is unique within group (i.e. collection)
        * but not globaly uniuqe, which should have no negative impact
        */
      let relationsBySourceCollectionId = groupBy(
        this.relationClientSettings.relations, 'sourceCollectionId')

      let groups = this.collectionsConfig.mapCollectionSchemas(this.collectionSchemas)
        .map(({collectionId}) => ({
          collectionId,
          relations: relationsBySourceCollectionId[collectionId]
        }))
        .filter(({relations}) => relations && relations.length)

      return groups
    }
  },
  methods: {
    sortedInGroup ({newIndex, oldIndex} = event, relations, groups) {
      relations.splice(newIndex, 0, relations.splice(oldIndex, 1)[0])

      // whenever the order changes due to drag n drop we layout the relations in
      // canonical order
      groups.reduce((l, {relations}) => l.concat(relations), [])
        .forEach((r, index) => r.order = index + 1)
    }
  }
}
</script>
<style>
  .drag-handle {
    cursor: grab;
  }
</style>
